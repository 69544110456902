import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { _ROUTES } from './../../config/page-route';
import { connect } from "react-redux";
import { useSelector } from 'react-redux';
import { WebUrl } from '../../util/Constant';

/// <summary>
/// Author: Juin
/// </summary>
const Content = () => {
    var _currentLocation = useLocation();
    var _navigate = useNavigate();
    var _isLoggedIn = useSelector(state => state.authState.isLoggedIn);
    const [init, setInit] = useState(false);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        window.scrollTo(0, 0);
        setInit(false);

        if (_currentLocation) {
            var currentRoute = _ROUTES.find((route) =>
                _currentLocation.pathname === route.path || (route.path.includes(":") && _currentLocation.pathname.includes(route.path.split(":")[0])));

            if (currentRoute == null) {
                window.history.pushState({}, document.title, WebUrl._URL_LOGIN);
            }
            else if (currentRoute.authRequired && !_isLoggedIn) {
                _navigate(WebUrl._URL_LOGIN, { replace: true });
            }
            else if (!currentRoute.authRequired && _isLoggedIn) {
                _navigate(WebUrl._URL_DASHBOARD, { replace: true });
            }
        }
        setInit(true);
    }, [_currentLocation, _isLoggedIn]);

    return (
        <>
            {
                init && <>
                    <Routes location={_currentLocation}>
                        {
                            _ROUTES.map((route, index) => (
                                <Route key={index} path={route.path} element={route.component} />
                            ))
                        }
                    </Routes>
                </>
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state
});

export default connect(mapStateToProps, null)(Content);
