import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import { _ROUTES } from './../config/page-route';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { numberWithCurrencyFormat } from '../util/Util';

/// <summary>
/// Author: Juin
/// </summary>
const HeaderName = () => {
    const { t } = useTranslation();
    const _USER_DATA = useSelector(state => state.authState.userData);
    const _location = useLocation();
    const _navigate = useNavigate();
    const currentPage = _ROUTES.find(route => route.path == _location.pathname);

    if (currentPage.backButton) {
        return (
            <div className="headercontainer">
                <div className='headerarrow'>
                    <IconChevronLeft cursor={"pointer"} strokeWidth={1.25} size={15} onClick={() => _navigate(-1)} />
                </div>
                <div className='headername'>
                    <h3>{currentPage.title == "TRANSACTION_DETAILS" || currentPage.title == "RECEIPT" ? _location?.state?.txnNo ?? '-' : t(currentPage.title)}</h3>
                </div>
                <div className='headercreditlimit'>
                    <h5>{t("CREDIT_LIMIT")}: {_USER_DATA ? numberWithCurrencyFormat(_USER_DATA.creditLimit) : 0}</h5>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="headercontainer">
                <h3 className='textcenter'>{t(currentPage.title)}</h3>
                <div className='headercreditlimit'>
                    <h5>{t("CREDIT_LIMIT")}: {_USER_DATA ? numberWithCurrencyFormat(_USER_DATA.creditLimit) : 0}</h5>
                </div>
            </div>
        );
    }
}

const Header = () => (
    <div className='headerdiv'>
        <HeaderName />
    </div>
)

export default Header;