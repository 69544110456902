import React from 'react';
import '../assets/css/page.css';
import { ReactComponent as HomeIcon } from '../assets/css/webfonts/homeIcon.svg';
import { ReactComponent as HomeInactiveIcon } from '../assets/css/webfonts/homeInactiveIcon.svg';
import { ReactComponent as CurrencyIcon } from '../assets/css/webfonts/currencyIcon.svg';
import { ReactComponent as CurrencyInactiveIcon } from '../assets/css/webfonts/currencyInactiveIcon.svg';
import { ReactComponent as TransIcon } from '../assets/css/webfonts/transIcon.svg';
import { ReactComponent as TransInactiveIcon } from '../assets/css/webfonts/transInactiveIcon.svg';
import { ReactComponent as ProfileIcon } from '../assets/css/webfonts/profileIcon.svg';
import { ReactComponent as ProfileInactiveIcon } from '../assets/css/webfonts/profileInactiveIcon.svg';
import { Grid } from '@mantine/core';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { WebUrl } from './../util/Constant';
import { useTranslation } from 'react-i18next';


/// <summary>
/// Author: Juin
/// </summary>
const HomeNav = () => {
    const _navigate = useNavigate();
    const _location = useLocation();
    const { t } = useTranslation();

    if (_location.pathname === WebUrl._URL_DASHBOARD) {
        return (
            <div>
                <HomeIcon cursor={"pointer"} width="30px" height="30px" onClick={() => _navigate(WebUrl._URL_DASHBOARD)} />
                <h5 className='navactive'>{t("HOME")}</h5>
            </div>
        );
    } else {
        return (
            <div>
                <HomeInactiveIcon cursor={"pointer"} width="30px" height="30px" onClick={() => _navigate(WebUrl._URL_DASHBOARD)} />
                <h5 className='navinactive'>{t("HOME")}</h5>
            </div>
        );
    }
}

/// <summary>
/// Author: Juin
/// </summary>
const CurrencyNav = () => {
    const _navigate = useNavigate();
    const _location = useLocation();
    const { t } = useTranslation();

    if (_location.pathname === WebUrl._URL_CURRENCY) {
        return (
            <div>
                <CurrencyIcon cursor={"pointer"} width="30px" height="30px" onClick={() => _navigate(WebUrl._URL_CURRENCY)} />
                <h5 className='navactive'>{t("CURRENCY")}</h5>
            </div>
        );
    } else {
        return (
            <div>
                <CurrencyInactiveIcon cursor={"pointer"} width="30px" height="30px" onClick={() => _navigate(WebUrl._URL_CURRENCY)} />
                <h5 className='navinactive'>{t("CURRENCY")}</h5>
            </div>
        );
    }
}

/// <summary>
/// Author: Juin
/// </summary>
const TransNav = () => {
    const _navigate = useNavigate();
    const _location = useLocation();
    const { t } = useTranslation();

    if (_location.pathname === WebUrl._URL_TRANSACTION || _location.pathname === WebUrl._URL_ADDTRANSACTION || _location.pathname === WebUrl._URL_TRANSACTIONDETAILS) {
        return (
            <div>
                <TransIcon cursor={"pointer"} width="30px" height="30px" onClick={() => _navigate(WebUrl._URL_TRANSACTION)} />
                <h5 className='navactive'>{t("TRANSACTION")}</h5>
            </div>
        );
    } else {
        return (
            <div>
                <TransInactiveIcon cursor={"pointer"} width="30px" height="30px" onClick={() => _navigate(WebUrl._URL_TRANSACTION)} />
                <h5 className='navinactive'>{t("TRANSACTION")}</h5>
            </div>
        );
    }
}

/// <summary>
/// Author: Juin
/// </summary>
const ProfileNav = () => {
    const _navigate = useNavigate();
    const _location = useLocation();
    const { t } = useTranslation();

    if (_location.pathname === WebUrl._URL_PROFILE || _location.pathname === WebUrl._URL_RESETPASS || _location.pathname === WebUrl._URL_CHANGEPHONE || _location.pathname === WebUrl._URL_CHANGELANGUAGE) {
        return (
            <div>
                <ProfileIcon cursor={"pointer"} width="30px" height="30px" onClick={() => _navigate(WebUrl._URL_PROFILE)} />
                <h5 className='navactive'>{t("PROFILE")}</h5>
            </div>
        );
    } else {
        return (
            <div>
                <ProfileInactiveIcon cursor={"pointer"} width="30px" height="30px" onClick={() => _navigate(WebUrl._URL_PROFILE)} />
                <h5 className='navinactive'>{t("PROFILE")}</h5>
            </div>
        );
    }
}

const Nav = () => (
    <div className='navstyle'>
        <Grid>
            <Grid.Col span={4} className='textcenter'>
                <HomeNav />
            </Grid.Col>
            {/* <Grid.Col span={3} className='textcenter'>
                <CurrencyNav />
            </Grid.Col> */}
            <Grid.Col span={4} className='textcenter'>
                <TransNav />
            </Grid.Col>
            <Grid.Col span={4} className='textcenter'>
                <ProfileNav />
            </Grid.Col>
        </Grid>
    </div>
)

export default Nav;