/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_COUNT_KEY = "totalCount";
    static _API_CODE_KEY = "code";
    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_POST = "POST";
    static _API_GET = "GET";

    static _API_INTERNAL_ERROR_CODE = 500;
    static _API_TEMPORARRY_REDIRECT_CODE = 307;
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary> 
export class ApiUrl {
    //user
    static _API_CHANGE_PHONE = "/Account/ChangePhone";
    static _API_CHANGE_PASSWORD = "/Account/ChangePassword";
    static _API_LOGIN = "/Account/Login";
    static _API_GET_USER = "/Account/GetUserDetailInit";
    static _API_LOGOUT = "/Account/Logout";
    static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
    static _API_TOKEN = "/Account/GenerateJwtToken";
    static _API_GET_AGENT_CURRENCIES = "/Account/GetAgentCurrencies";

    //transaction
    static _API_GET_TRANSACTION_DETAILS = "/Transaction/GetTransactionDetails/{id}";
    static _API_TRANSACTION_SUMMARY = "/Transaction/GetDailyTransactionSummary";
    static _API_TRANSACTION_SUMMARY_CURRENCY = "/Transaction/GetDailyTransactionSummaryByCurrency";
    static _API_TRANSACTION_LIST = "/Transaction/GetAgentTransaction";
    static _API_CREATE_OR_UPDATE_TRANSACTION = "/Transaction/CreateOrUpdateTransaction";
    static _API_GET_TRANSACTION_DETAILS = "/Transaction/GetTransactionDetails/{id}";

    //configuration
    static _API_GET_CURRENCY = "/Configuration/GetCurrency";
    static _API_GET_BANKS = "/Configuration/GetBanks";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    static _URL_MAIN = "/";
    static _URL_LOGIN = "/login";
    static _URL_LOGINSUCCESS = "/loginSuccess";
    static _URL_DASHBOARD = "/dashboard";
    static _URL_PROFILE = "/user/profile";
    static _URL_CHANGEPHONE = "/user/changePhone";
    static _URL_RESETPASS = "/user/resetPass";
    static _URL_CHANGELANGUAGE = "/user/changeLanguage";
    static _URL_CURRENCY = "/currency/currency";
    static _URL_TRANSACTION = "/transaction/transaction";
    static _URL_ADDTRANSACTION = "/transaction/addTransaction";
    static _URL_TRANSACTIONDETAILS = "/transaction/transactionDetails";
    static _URL_RECEIPT = "/transaction/receipt";
    static _URL_ATTACHMENT = "/transaction/attachment";
}

/// <summary>
/// Author : -
/// </summary>
export class Status {
    static _ENABLED = 1;
    static _DISABLED = 0;
    static _DELETED = 2;
}

/// <summary>
/// Author: -
/// this value needed to be tally to value in server, do not change recklessly
/// </summary> 
export class AccessRight {
    static _MAIN_ACCOUNT_PERMISSION = "Main Account";
    static _COMPANY_PERMISSION = "Company";
    static _ADMIN_PERMISSION = "Admin";
    static _SHAREHOLDER_PERMISSION = "Shareholder";
    static _AGENT_PERMISSION = "Agent";
    static _MEMBER_PERMISSION = "Member";
    static _CURRENCY_PERMISSION = "Currency";
    static _BANK_PERMISSION = "Bank";
    static _CURRENCY_STOCK_PERMISSION = "Currency Stock";
    static _TRANSACTION_PERMISSION = "Transaction";
    static _COMPANY_REPORT_PERMISSION = "Company Report";
    static _DASHBOARD_PERMISSION = "Dashboard";

    static _VIEW_PERMISSION = "1";
    static _CREATE_PERMISSION = "2";
    static _EDIT_PERMISSION = "3";
    static _DELETE_PERMISSION = "4";
}

/// <summary>
/// Author : Juin
/// </summary>
export class Language {
    static _CHINESE = "zh";
    static _ENGLISH = "en";
    static _BAHASA_MELAYU = "ms";
}

/// <summary>
/// Author : Juin
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
    static _LOGIN_GUID = "loginGuid";
    static _LOGIN_TOKEN = "loginToken";
    static _PAGE = "page";
}

/// <summary>
/// Author: -
/// </summary>
export class InputValidationKey {
    static _REQUIRED_KEY = "required";
    static _MAX_LENGTH_KEY = "maxLength";
    static _MIN_LENGTH_KEY = "minLength";
    static _MIN_KEY = "min";
    static _MAX_KEY = "max";
    static _PATTERN = "pattern";
}

/// <summary>
/// Author: -
/// </summary>
export class AlertTypes {
    static _DEFAULT = 'default';
    static _INFO = 'info';
    static _SUCCESS = 'success';
    static _WARNING = 'warning';
    static _DANGER = 'danger';
    static _ERROR = 'error';
    static _INPUT = 'input';
    static _CUSTOM = 'custom';
}

/// <summary>
/// Author: -
/// </summary>
export class ValidationPattern {
    static _emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static _phoneNumberRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
    static _numberRegex = /^([1-9]|[1-9][0-9]|[1-9][0-9]*)$/;
    static _decimalRegex = /^[1-9][0-9]*(\.\d{1,2})?$|^[0](\.\d{1,2})?$/;
    static _digitRegex = /^\d+$/;
    static _twoDecimalAndLargerThanZero = /^(?!00?\.00$)\d{1,2}(?:\.\d{1,2})?$/;
}

/// <summary>
/// Author: -
/// </summary>
export const _SELECT_STATUS_OPTIONS = [
    { value: false, label: "DISABLED" },
    { value: true, label: "ENABLED" }
]

/// <summary>
/// Author: -
/// </summary>
export class Role {
    static _SUB_ACCOUNT = -1;
    static _SUPER_ADMIN = 1;
    static _COMPANY = 2;
    static _SHAREHOLDER = 3;
    static _ADMIN = 4;
    static _AGENT = 5;
    static _MEMBER = 6;
}

/// <summary>
/// Author: -
/// </summary>
export class LocalStorageKey {
}

/// <summary>
/// Author: -
/// </summary>
export const _LOCAL_STORAGE_DISPATCH = [

]

/// <summary>
/// Author: -
/// </summary>
export const _SELECT_DEBOUNCE_MS = 50;

/// <summary>
/// Author: Juin
/// </summary>
export class TransactionStatus {
    static _PENDING = '0';
    static _APPROVED = '1';
    static _REJECTED = '2';
    static _ON_HOLD = '3';
    static _PENDING_AGENT_APPROVAL = '4';
    static _ALL = '-1';
}

/// <summary>
/// Author: Juin
/// </summary>
export class ListingFilter {
    static _TRANSACTION = 'Transaction';
}

/// <summary>
/// Author: Juin
/// </summary>
export class MimeTypes {
    static _PDF = 'application/pdf';
    static _JPEG = 'image/jpeg';
    static _JPG = 'image/jpg';
    static _PNG = 'image/png';
    static _GIF = 'image/gif';
    static _BMP = 'image/bmp';
}

/// <summary>
/// Author: Juin
/// </summary>
export class FileTypes {
    static _PDF = 'pdf';
    static _JPEG = 'jpeg';
    static _JPG = 'jpg';
    static _PNG = 'png';
    static _GIF = 'gif';
    static _BMP = 'bmp';
}

/// <summary>
/// Author: CK
/// </summary>
export const _SELECT_TRANSACTION_STATUS_OPTIONS = [
    { value: TransactionStatus._PENDING_AGENT_APPROVAL, label: "PENDING_AGENT_APPROVAL" },
    { value: TransactionStatus._PENDING, label: "PENDING" },    
    { value: TransactionStatus._APPROVED, label: "APPROVED" },
    { value: TransactionStatus._REJECTED, label: "REJECTED" },
    { value: TransactionStatus._ON_HOLD, label: "ON_HOLD" }
]

/// <summary>
/// Author: CK
/// </summary>
export class RateMethod {
    static _MULTIPLY = 0;
    static _DIVIDE = 1;
}

/// <summary>
/// Author: Rock
/// </summary>
export class MathOperator {
    static _ADDITION = 1;
    static _SUBTRACTION = 2;
    static _MULTIPLY = 3;
    static _DIVIDE = 4;
}

/// <summary>
/// Author: Rock
/// </summary>
export const _RATE_DP = 4;