import { createFormBody, showMessage } from "../util/Util";
import { ApiKey, ApiUrl, WebUrl, SessionKey, AlertTypes } from "../util/Constant";
import { setBusy, setIdle, displayMessage } from "./AppAction";
import { fetch } from 'whatwg-fetch';
import ApiEngine from '../util/ApiEngine';

export const _LOGIN_SUCCESS = 'LoginSuccess';
export const _LOGIN_FAILED = 'LoginFailed';
export const _LOGOUT = 'Logout';
export const _ACTION_FORBIDDEN = 'ActionForbidden';
export const _UPDATE_USER = 'UpdateUser';

/// <summary>
/// Author : Juin
/// </summary>
export function performLogin(username, password) {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });

        let params = {
            "username": username,
            "password": password,
        };
        let formBody = createFormBody(params);
        dispatch(setBusy());

        try {
            const response = await fetch(ApiUrl._API_LOGIN, {
                method: ApiKey._API_POST,
                headers: {
                    'Content-Type': ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                },
                body: formBody
            });

            const result = await response.json();

            if (result[ApiKey._API_SUCCESS_KEY]) {
                var userData = result[ApiKey._API_DATA_KEY];

                dispatch({
                    type: _LOGIN_SUCCESS,
                    userData: userData,
                });
            }
            else {
                dispatch({
                    type: _LOGIN_FAILED,
                    message: result[ApiKey._API_MESSAGE_KEY],
                });

                dispatch(displayMessage(result[ApiKey._API_SUCCESS_KEY], result[ApiKey._API_MESSAGE_KEY], WebUrl._URL_LOGIN));
            }
            dispatch(setIdle());
            return result;

        } catch (error) {
            dispatch(setIdle());
            dispatch(displayMessage(false, "Internal Server Error", WebUrl._URL_LOGIN));
        }
    }
}

/// <summary>
/// Author : CK
/// </summary>
export function isLoggedIn() {
    return async dispatch => {
        try {
            const result = await ApiEngine.get(`${ApiUrl._API_IS_LOGGED_IN}`, { headers: { "skipLoading": true } });

            if (result[ApiKey._API_SUCCESS_KEY] == true) {
                await dispatch(updateLoginUser(result[ApiKey._API_DATA_KEY]));
            }

            return result;
        } catch (error) {
            dispatch(setIdle());
            dispatch(displayMessage(false, "Internal Server Error", WebUrl._URL_LOGIN));
        }
    }
}

/// <summary>
/// Author : Juin
/// this function is to only update user status to login if it is already considered authenticated in cookie
/// </summary>
export const updateLoginUser = (userData) => {
    return {
        type: _UPDATE_USER,
        userData: userData,
    }
}

/// <summary>
/// Author : Juin
/// expose logout action as function to be called from classes
/// </summary>
export const performLogout = () => {
    return async dispatch => {
        const response = await fetch(ApiUrl._API_LOGOUT, {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                "Authorization": "Bearer " + localStorage.getItem(SessionKey._LOGIN_TOKEN)
            },
        });

        const result = await response.json();

        if (result[ApiKey._API_SUCCESS_KEY]) {
            dispatch({
                type: _LOGOUT
            });
        }
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to logout
/// </summary>
export const resetLogin = () => {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });
    }
}