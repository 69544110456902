import React, { useEffect } from "react";
import { PageSettings } from "./config/page-settings.js";
import { Redirect } from "react-router";
import Content from "./components/content/content.jsx";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import {
    ApiKey,
    ApiUrl,
    WebUrl,
    AlertTypes,
    SessionKey,
    LocalStorageKey,
    Language,
    _LOCAL_STORAGE_DISPATCH,
} from "./util/Constant";
import { isObjectEmpty, stringIsNullOrEmpty, showMessage } from "./util/Util";
import { disposeMessage, displayMessage } from "./redux/AppAction.js";
import { updateLoginUser, resetLogin } from "./redux/AuthAction.js";
import { fetch } from "whatwg-fetch";
import Notiflix from "notiflix";
import { Notify, Report, Confirm, Loading, Block } from "notiflix";
import { createStorageListener } from "./util/Util";
import store from "./ApplicationStore.js";
import { AppShell, LoadingOverlay, Modal } from "@mantine/core";
import './assets/css/page.css';
import Headerr from './pages/Header.js';
import Nav from './pages/Nav.js';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import successPopUp from './assets/img/successPopUp.png';
import failPopUp from './assets/img/failPopUp.png';
import { closeMessage } from './redux/AppAction';
import ApiEngine from './util/ApiEngine';
import { useState } from "react";
import Cookies from "universal-cookie";

/// <summary>
/// Author: Juin
/// </summary>
const UsePathName = () => {
    const location = useLocation();
    const withoutNavPaths = [WebUrl._URL_RECEIPT, WebUrl._URL_CHANGEPHONE, WebUrl._URL_RESETPASS, WebUrl._URL_CHANGELANGUAGE, WebUrl._URL_TRANSACTIONDETAILS];
    const withoutHeaderNavPaths = [WebUrl._URL_LOGIN, WebUrl._URL_MAIN];

    if (withoutHeaderNavPaths.includes(location.pathname)) {
        return (
            <div className="app">
                <Content />
            </div>
        );
    } else if (withoutNavPaths.includes(location.pathname)) {
        return (
            <div className="receiptapp">
                <Headerr />
                <div style={{ height: "100%", paddingTop: "3.5em", overflow: "auto" }}>
                    <Content />
                </div>
            </div>
        );
    } else {
        return (
            <div className="app">
                <Headerr />
                <div style={{ height: "100%", paddingTop: "3.5em", overflow: "auto" }}>
                    <Content />
                </div>
                <Nav />
            </div>
        );
    }
}

const App = () => {
    var _appState = useSelector(state => state.appState);
    var { isLoggedIn } = useSelector(state => state.authState);

    useEffect(() => {
        Notiflix.Report.Init({
            className: "notiflix-report",
            width: "320px",
            backgroundColor: "#f8f8f8",
            borderRadius: "25px",
            rtl: false,
            zindex: 4002,
            backOverlay: true,
            backOverlayColor: "rgba(0,0,0,0.5)",
            useGoogleFont: false,
            fontFamily: "Quicksand",
            svgSize: "110px",
            plainText: true,
            titleFontSize: "16px",
            titleMaxLength: 34,
            messageFontSize: "13px",
            messageMaxLength: 400,
            buttonFontSize: "14px",
            buttonMaxLength: 34,
            cssAnimation: true,
            cssAnimationDuration: 360,
            cssAnimationStyle: "fade",

            success: {
                svgColor: "#32c682",
                titleColor: "#1e1e1e",
                messageColor: "#242424",
                buttonBackground: "#32c682",
                buttonColor: "#fff",
                backOverlayColor: "rgba(50,198,130,0.2)",
            },

            failure: {
                svgColor: "#ff5549",
                titleColor: "#1e1e1e",
                messageColor: "#242424",
                buttonBackground: "#ff5549",
                buttonColor: "#fff",
                backOverlayColor: "rgba(255,85,73,0.2)",
            },

            warning: {
                svgColor: "#eebf31",
                titleColor: "#1e1e1e",
                messageColor: "#242424",
                buttonBackground: "#eebf31",
                buttonColor: "#fff",
                backOverlayColor: "rgba(238,191,49,0.2)",
            },

            info: {
                svgColor: "#26c0d3",
                titleColor: "#1e1e1e",
                messageColor: "#242424",
                buttonBackground: "#26c0d3",
                buttonColor: "#fff",
                backOverlayColor: "rgba(38,192,211,0.2)",
            },
        });

        Notiflix.Confirm.Init({
            position: "center",
            distance: "200px",
            titleColor: "#000000",
            plainText: false,
            messageMaxLength: 500,
            backgroundColor: "#f8f8f8",
            okButtonColor: "#f8f8f8",
            okButtonBackground: "#32c682",
            cancelButtonColor: "#000000",
            cancelButtonBackground: "#f8f8f8",
        });

        window.addEventListener("storage", createStorageListener(store));
    }, [])

    var { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const _cookies = new Cookies();;
    const success = () => {
        dispatch(closeMessage());
        navigate(_appState.page);
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        _cookies.set(SessionKey._LANGUAGE
            , (localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH)
            , { path: "/" });
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        (async () => {
            if (!init) {
                if (!stringIsNullOrEmpty(localStorage.getItem(SessionKey._LOGIN_GUID)) && !stringIsNullOrEmpty(localStorage.getItem(SessionKey._LOGIN_TOKEN))) {
                    const result = await ApiEngine.get(`${ApiUrl._API_IS_LOGGED_IN}`);

                    if (result[ApiKey._API_SUCCESS_KEY] == true) {
                        await dispatch(updateLoginUser(result[ApiKey._API_DATA_KEY]));
                    }
                }
                setInit(true);
            }
        })();
    }, [isLoggedIn, init]);

    return (
        <PageSettings.Provider>
            <LoadingOverlay
                visible={_appState.isBusy}
                zIndex={9999}
                loaderProps={{ size: 'md', color: '#743fd3', variant: 'dots' }}
                overlayOpacity={0.5}
                overlayColor="#c5c5c5"
                styles={{
                    overlay: (base) => ({
                        ...base,
                        zIndex: "999",
                        position: "fixed",
                    }),
                }}
            ></LoadingOverlay>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" /> {/*to prevent zoom in in input fields */}
            <Modal opened={_appState.popup} onClose={() => { _appState.status ? success() : dispatch(closeMessage()); }} radius={30} withCloseButton centered closeButtonProps={{size: 'lg'}}>
                <div className='textcenter msgbox'>
                    <img src={_appState.status ? successPopUp : failPopUp} />
                    <h5 className='normalweight msg'>{_appState.message}</h5>
                </div>
            </Modal>
            {init && <UsePathName />}
        </PageSettings.Provider>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

export default connect(mapStateToProps, null)(App);
