import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router';
import { WebUrl, AccessRight, Role } from "../util/Constant";

/// <summary>
/// Author: -
/// </summary>
export const load = (Component: any) => (props: any) => (
    <Suspense fallback={<div className="loader-wrapper"></div>}>
        <Component {...props} />
    </Suspense>
)

const Login = load(lazy(() => import('./../pages/Login')));
const LoginSuccess = load(lazy(() => import('./../pages/LoginSuccess')));
const Dashboard = load(lazy(() => import('./../pages/Dashboard')));
const Profile = load(lazy(() => import('./../pages/user/Profile')));
const ChangePhone = load(lazy(() => import('./../pages/user/ChangePhone')));
const ResetPass = load(lazy(() => import('./../pages/user/ResetPass')));
const ChangeLanguage = load(lazy(() => import('./../pages/user/ChangeLanguage')));
const Currency = load(lazy(() => import('./../pages/currency/Currency')));
const Transaction = load(lazy(() => import('./../pages/transaction/Transaction')));
const AddTransaction = load(lazy(() => import('./../pages/transaction/AddTransaction')));
const TransactionDetails = load(lazy(() => import('./../pages/transaction/TransactionDetails')));
const Receipt = load(lazy(() => import('./../pages/transaction/Receipt')));
const Attachment = load(lazy(() => import('./../pages/transaction/Attachment')));

/// <summary>
/// Author: Juin
/// </summary>
export const _ROUTES = [
    {
        path: WebUrl._URL_MAIN,
        exact: true,
        title: 'LOGIN',
        authRequired: false,
        component: <Login />,
        backButton: false
    },
    {
        path: WebUrl._URL_LOGIN,
        exact: true,
        title: 'LOGIN',
        authRequired: false,
        component: <Login />,
        backButton: false
    },
    {
        path: WebUrl._URL_LOGINSUCCESS,
        exact: true,
        title: 'Login Success',
        authRequired: false,
        component: <LoginSuccess />,
        backButton: false
    },
    {
        path: WebUrl._URL_DASHBOARD,
        exact: true,
        title: 'DASHBOARD',
        authRequired: true,
        component: <Dashboard />,
        backButton: false
    },
    {
        path: WebUrl._URL_PROFILE,
        exact: true,
        title: 'PROFILE',
        authRequired: true,
        component: <Profile />,
        backButton: false
    },
    {
        path: WebUrl._URL_CHANGEPHONE,
        exact: true,
        title: 'CHANGE_PHONE_NUMBER',
        authRequired: true,
        component: <ChangePhone />,
        backButton: true
    },
    {
        path: WebUrl._URL_RESETPASS,
        exact: true,
        title: 'RESET_PASSWORD',
        authRequired: true,
        component: <ResetPass />,
        backButton: true
    },
    {
        path: WebUrl._URL_CHANGELANGUAGE,
        exact: true,
        title: 'CHANGE_LANGUAGE',
        authRequired: true,
        component: <ChangeLanguage />,
        backButton: true
    },
    // {
    //     path: WebUrl._URL_CURRENCY,
    //     exact: true,
    //     title: 'CURRENCY',
    //     authRequired: true,
    //     component: <Currency />,
    //     backButton: false
    // },
    {
        path: WebUrl._URL_TRANSACTION,
        exact: true,
        title: 'TRANSACTION',
        authRequired: true,
        component: <Transaction />,
        backButton: false
    },
    {
        path: WebUrl._URL_ADDTRANSACTION,
        exact: true,
        title: 'ADD_TRANSACTION',
        authRequired: true,
        component: <AddTransaction />,
        backButton: true
    },
    {
        path: WebUrl._URL_TRANSACTIONDETAILS,
        exact: true,
        title: 'TRANSACTION_DETAILS',
        authRequired: true,
        component: <TransactionDetails />,
        backButton: true
    },
    {
        path: WebUrl._URL_RECEIPT,
        exact: true,
        title: 'RECEIPT',
        authRequired: true,
        component: <Receipt />,
        backButton: true
    },
    {
        path: WebUrl._URL_ATTACHMENT,
        exact: true,
        title: 'ATTACHMENT',
        authRequired: true,
        component: <Attachment />,
        backButton: true
    }
];

export default _ROUTES;